import React, { useContext } from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TitleBanner from "../components/titleBanner/TitleBanner"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import CardsList from "../components/common/cardsList/CardsList"
import Reviews from "../components/product-detail/itemReviews/Reviews"
import { GlobalStateContext } from "../context/GlobalContextProvider"

let CoursesAutodesk = ({ data }) => {
  const { allContentfulTraining } = data
  const autodeskCourses = allContentfulTraining.nodes
  return (
    <>
      <SEO
        title="Best IFC Training Course in Singapore | Ace Industrial Academy"
        description="Ace Industrial Academy is a Skillsfuture Singapore Approved Training Organisation. Learn more about CAD, BIM, and IFC here today."
      />

      <TitleBannerEnquiry
        title="Industry Foundation Classes (IFC) for BIM Courses"
        description="Start working on BIM collaboratively with openBIM (IFC)"
        withOnlyDescription
        withoutFilter
      />

      <div className="container">
        <CardsList
          withoutFilter
          courses={autodeskCourses}
        />
        {/* <div className="reviews-cover">
          <Reviews />
        </div> */}
      </div>
    </>
  )
}

export const autodeskQuery = graphql`
  query allIfcQuery {
    allContentfulTraining(
      filter: { category: { eq: "ifc" } }
    ) {
      nodes {
        id
        title
        courseReference
        trainingMode
        updatedAt
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        metaDescription
        category
        slug
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesAutodesk
